<template>
  <div>
    <div class="banner">
    </div>
  </div>
  <div class="zone">
    <div class="area-title">
      <p class="title">核心优势</p>
    </div>
    <div>
      <div class="zone-child">
        <span class="child-title">控本</span>
        <div class="cost">
          <div
            class="cost-item"
            v-for="(item, index) in control"
            :key="index"
          >
            <div
              class="cost-item--default"
              :style="{'background-image': 'url('+item.bg+')'}"
            >
              <img
                class="cost-item--default_icon"
                :src="item.icon"
              >
              <p class="cost-item--default_title">{{item.title}}</p>
              <p class="cost-item--default_desc">
                {{item.desc}}
              </p>
            </div>
            <div class="cost-item--hover">
              <img
                class="cost-item--hover_icon"
                :src="item.hover.icon"
              >
              <p class="cost-item--hover_title">{{item.hover.title}}</p>
              <div class="cost-item--hover_desc">
                <p
                  class="cost-item--hover_di"
                  v-for="(d, idx) in item.hover.desc"
                  :key="idx"
                >{{d}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="zone-child">
        <span class="child-title">增效</span>
        <div class="cost">
          <div
            class="cost-item"
            v-for="(item, index) in profit"
            :key="index"
          >
            <div
              class="cost-item--default"
              :style="{'background-image': 'url('+item.bg+')'}"
            >
              <img
                class="cost-item--default_icon"
                :src="item.icon"
              >
              <p class="cost-item--default_title">{{item.title}}</p>
              <p class="cost-item--default_desc">
                {{item.desc}}
              </p>
            </div>
            <div class="cost-item--hover">
              <img
                class="cost-item--hover_icon"
                :src="item.hover.icon"
              >
              <p class="cost-item--hover_title">{{item.hover.title}}</p>
              <div class="cost-item--hover_desc">
                <p
                  class="cost-item--hover_di"
                  v-for="(d, idx) in item.hover.desc"
                  :key="idx"
                >{{d}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "about",
  components: {

  },
  data() {
    return {
      control: [
        {
          bg: require('@/assets/images/strength-advantage/v1/bg1.png'),
          icon: require('@/assets/images/strength-advantage/v1/icon1.png'),
          title: '产品研发',
          desc: '新品研发，爆款制造',
          hover: {
            icon: require('@/assets/images/strength-advantage/v1/icon11.png'),
            title: '产品研发',
            desc:
              [
                '产品品质及稳定性是客户关注的重点，也是客户选择我们的重要原因。',
                `至灿集团于2020年先后成立了广元至和食品有限公司、广元至高食品有限公司、广元至旺食品有限公司，
                专注于火锅食材及酱卤肉的研发、生产、加工，为合作伙伴及消费者建立更加稳定的、专业化的产品服务。`
              ]
          }
        },
        {
          bg: require('@/assets/images/strength-advantage/v1/bg2.png'),
          icon: require('@/assets/images/strength-advantage/v1/icon2.png'),
          title: '集中采购',
          desc: '品好价优，省心采购',
          hover: {
            icon: require('@/assets/images/strength-advantage/v1/icon22.png'),
            title: '集中采购',
            desc:
              [
                '至灿采购坚持集中化、规模化、标准化的采购模式，有效的把控供应渠道，为客户提供更具竞争优势的产品。',
                '把好品质、控好价格，最大程度的提高净料率、降低货损率是我们一直以来坚持的原则。'
              ]
          }
        }
      ],
      profit: [
        {
          bg: require('@/assets/images/strength-advantage/v1/bg4.png'),
          icon: require('@/assets/images/strength-advantage/v2/icon44.png'),
          title: '电商运营',
          desc: '流量密码，最新玩法',
          hover: {
            icon: require('@/assets/images/strength-advantage/v2/icon4.png'),
            title: '电商运营',
            desc:
              [
                `成都小黄橙科技有限公司，是至灿集团旗下专注线上运营业务的子公司。`,
                '主要业务是针对本地生活、新媒体、电商平台以及消费者，提供营销策划，运营推广，渠道联动，品牌维护等一系列服务。',
                '到店板块通过大流量互联网平台进行推广，结合新美大运营服务为门店和品牌引流增收，到家板块通过外卖平台运营服务带来多渠道创收。'
              ]
          }
        },
        {
          bg: require('@/assets/images/strength-advantage/v1/bg5.png'),
          icon: require('@/assets/images/strength-advantage/v2/icon55.png'),
          title: '仓配一体',
          desc: '三温仓存储，极速送达',
          hover: {
            icon: require('@/assets/images/strength-advantage/v2/icon5.png'),
            title: '仓配一体',
            desc:
              [
                `广汉至灿仓储服务有限公司，于2020年成立的集仓储、物流、配送于一体的服务平台，
                实现对客户即时下单、即时配送、极速送达的服务目标，降低门店采购端的等侯周期，
                降低货损，提高库存周转率，做低库存，降低存货风险。`
              ]
          }
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.banner {
  height: 36.5vw;
  background-image: url('../../../assets/images/strength-about/v1/banner.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.zone {
  padding: 4.9rem 0 0;
  background-image: url('../../../assets/images/strength-advantage/ys/bg.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.zone-child {
  padding-top: 12rem;
  .child-title {
    color: #ffffff;
    background-color: #e61d0e;
    border-radius: 1rem;
    display: inline-block;
    height: 2.8rem;
    line-height: 2.8rem;
    padding: 0.6rem 2rem 1rem;
    font-size: 3rem;
    font-weight: bold;
    margin-left: 50%;
    margin-bottom: 3.7rem;
    transform: translateX(-50%);
    box-shadow: 0rem 0rem 2rem 0rem rgba(186, 186, 186, 0.49);
  }
}
.cost {
  width: 100%;
  display: flex;
  .cost-item {
    position: relative;
    flex: 1;
    width: 33.33vw;
    height: 33.33vw;
    text-align: center;
    box-sizing: border-box;
    &:hover {
      .cost-item--hover {
        opacity: 1;
        transform: rotate(0) scale(1);
        transition: all 0.5s ease-in-out;
      }
    }
    &--default {
      width: 100%;
      height: 100%;
      padding-top: 9.4vw;
      box-sizing: border-box;
      background-position: -3vw -3vw;
      background-size: 110% 120%;
      background-repeat: no-repeat;
      &_icon {
        width: 6vw;
        height: auto;
        margin-bottom: 3vw;
      }
      &_title {
        font-size: 1.875vw;
        color: #333333;
        font-weight: bold;
        margin-bottom: 2.08vw;
      }
      &_desc {
        font-size: 1.25vw;
        color: #333333;
      }
    }
    &--hover {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      padding-top: 4vw;
      background-color: #e61d0e;
      box-sizing: border-box;
      transform: rotate(360deg) scale(0.1);
      transition: all 0.5s ease-in-out;
      &_icon {
        width: 6vw;
        height: auto;
        margin-bottom: 1vw;
      }
      &_title {
        font-size: 1.35vw;
        color: #ffffff;
        font-weight: bold;
        margin-bottom: 1vw;
      }
      &_desc {
        margin: 0 auto;
        margin-top: 2vw;
        margin-bottom: 2vw;
        font-size: 1vw;
        font-weight: 500;
        max-width: 60%;
        line-height: 2vw;
        text-align: left;
      }
      &_di {
        color: #ffffff;
        margin-bottom: 0.5vw;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
      }
    }
  }
}
.area-title {
  text-align: center;
  .title {
    position: relative;
    font-size: 3rem;
    font-weight: bold;
    color: #000000;
    &::before {
      position: absolute;
      top: 5.4rem;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      width: 10rem;
      height: 0.4rem;
      background-color: #e61d0e;
      border-radius: 0.2rem;
    }
  }
}
</style>
